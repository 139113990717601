<template>
  <section class="screen-controller">
    <div class="screen-controller__bg">
      <slot class="screen-controller__bg-img" name="img">
        <UiImage src="/static/techwork/bg.png" />
      </slot>
    </div>
    <div class="screen-controller__content">
      <h1>{{ $t('techWork.title') }}</h1>
      <p>{{ $t('techWork.description1') }}</p>
      <p>{{ $t('techWork.description2') }}</p>
    </div>
  </section>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped src="./ParentScreenController.scss" />
